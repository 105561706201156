export default {
    data() {
        return {
            editableFiles: ['text/', 'x-empty', 'application/csv', 'json', 'yaml', 'yml']
        }
    },
    methods: {
        isEditableFile(file) {
            return file.size <= 1000000 && this.editableFiles.some(mime => this.mimeType.includes(mime))
        }
    }
}
